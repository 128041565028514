import {
  DateField,
  Labeled,
  NumberField,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import PreservationSeriesField from '../common/PreservationSeriesField';
import LabeledWithCheckAccess from '../common/LabeledWithCheckAccess';
import PreservationItemField from '../common/PreservationItemField';
import SignatureTextField from '../common/SignatureTextField';
import AssignedField from '../common/Assigned/AssignedField';
import AttachmentList from '../attachment/AttachmentList';
import ComponentField from '../common/ComponentField';
import LocationField from '../common/LocationField';
import { commentType } from '../common/constants';
import CommentList from '../comment/CommentList';
import AuditListTab from '../audit/AuditListTab';
import { RESOURCE_COMMENT } from '../constants';
import TestField from '../common/TestField';
import CompletedField from './components/CompletedField';
import CustomBooleanField from '../common/CustomBooleanField';

const PreservationItemActivityShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Preservation Item Activity">
          <Box>
            <Labeled
              label={labels['preservationItemId']}
              sx={commonStyles.flexBoxItem}
            >
              <PreservationItemField />
            </Labeled>
            <Labeled
              label={labels['preservationSeriesId']}
              sx={commonStyles.flexBoxItem}
            >
              <PreservationSeriesField />
            </Labeled>
            <Labeled
              label={labels['componentId']}
              sx={commonStyles.flexBoxItem}
            >
              <ComponentField />
            </Labeled>
            <Labeled label={labels['testId']} sx={commonStyles.flexBoxItem}>
              <TestField textSource="test.testFormNo" />
            </Labeled>
            <Labeled label={labels['locationId']} sx={commonStyles.flexBoxItem}>
              <LocationField />
            </Labeled>
            <Labeled
              label={labels['isClosed'] || 'Closed'}
              sx={commonStyles.flexBoxItem}
            >
              <CustomBooleanField source="isClosed" />
            </Labeled>
            <Labeled
              label={labels['sequence'] || 'Sequence'}
              sx={commonStyles.flexBoxItem}
            >
              <TextField source="sequence" />
            </Labeled>
            <Labeled
              label={labels['estimatedManHours'] || 'Estimated Man Hours'}
              sx={commonStyles.flexBoxItem}
            >
              <NumberField source="estimatedManHours" />
            </Labeled>
            <Labeled
              label={labels['onSiteDate'] || 'On Site Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="onSiteDate" />
            </Labeled>
            <Labeled
              label={labels['isMobile'] || 'Mobile'}
              sx={commonStyles.flexBoxItem}
            >
              <CustomBooleanField source="isMobile" />
            </Labeled>
            <Labeled
              label={labels['assigned'] || 'Responsible'}
              sx={commonStyles.flexBoxItem}
            >
              <AssignedField />
            </Labeled>
            <Labeled
              label={labels['actualManHours'] || 'Actual Man Hours'}
              sx={commonStyles.flexBoxItem}
            >
              <NumberField source="actualManHours" />
            </Labeled>
            <Labeled
              label={labels['adminManHours'] || 'Admin Man Hours'}
              sx={commonStyles.flexBoxItem}
            >
              <NumberField source="adminManHours" />
            </Labeled>
            <LabeledWithCheckAccess
              label={labels['inspector'] || 'Inspector'}
              sx={commonStyles.flexBoxItem}
            >
              <SignatureTextField source="inspector" />
            </LabeledWithCheckAccess>
            <LabeledWithCheckAccess
              label={labels['signOffDate'] || 'Sign Off Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="signOffDate" />
            </LabeledWithCheckAccess>
            <LabeledWithCheckAccess
              label={labels['completed'] || 'Completed'}
              sx={commonStyles.flexBoxItem}
            >
              <CompletedField source="completed" />
            </LabeledWithCheckAccess>

            <Labeled
              label={labels['verifiedBy'] || 'Verified By'}
              sx={commonStyles.flexBoxItem}
            >
              <SignatureTextField source="verifiedBy" />
            </Labeled>
            <Labeled
              label={labels['verifiedDate'] || 'Verified Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="verifiedDate" />
            </Labeled>
            <Labeled
              label={labels['startDate'] || 'Start Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="startDate" />
            </Labeled>
            <Labeled
              label={labels['endDate'] || 'End Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="endDate" />
            </Labeled>
            <Labeled
              label={labels['frequency'] || 'Frequency'}
              sx={commonStyles.flexBoxItem}
            >
              <NumberField source="frequency" />
            </Labeled>
            <Labeled
              label={labels['dueDate'] || 'Due Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="dueDate" />
            </Labeled>
            <Labeled
              label={labels['lateDate'] || 'Late Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="lateDate" />
            </Labeled>
            <Labeled
              label={labels['storagePreservation'] || 'Storage Preservation'}
              sx={commonStyles.flexBoxItem}
            >
              <TextField source="storagePreservation" />
            </Labeled>
            <Labeled
              label={labels['plusDate'] || 'Plus Date'}
              sx={commonStyles.flexBoxItem}
            >
              <NumberField source="Plusdate" />
            </Labeled>
            <Labeled
              label={labels['printedBy'] || 'Printed By'}
              sx={commonStyles.flexBoxItem}
            >
              <TextField source="printedBy" />
            </Labeled>
            <Labeled
              label={labels['printDate'] || 'Printed Date'}
              sx={commonStyles.flexBoxItem}
            >
              <DateField source="printDate" />
            </Labeled>
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="comments" path="comments">
          <CommentList
            resource={RESOURCE_COMMENT}
            targetId="preservation_item_activity_id"
            commentType={commentType.preservation_item_activity}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Attachment"
          id="attachment_tab"
          sx={commonStyles.formTabHeader}
        >
          <AttachmentList targetId="preservationItemActivityId" onlyShow />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_preservation_item_activity" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default PreservationItemActivityShow;
