import { get, cloneDeep } from 'lodash';

export const makeRequestBody = (data, companyName, isCreate?) => {
  const reqBody = cloneDeep(data);
  reqBody.name = data.firstName + ' ' + data.lastName;
  reqBody.jobTitle = reqBody.jobTitle ?? '';
  reqBody.externalCompany = reqBody.externalCompany ?? '';
  reqBody.pinCode = reqBody.pinCode ?? '';

  if (isCreate) {
    reqBody.companies[companyName].name = companyName;
    reqBody.companies[companyName].defaultDivisionId = data.divisionId;
    reqBody.companies[companyName].isCompanyAccessDisabled = false;
  }

  if (Array.isArray(reqBody.companies[companyName].projects)) {
    const projects = reqBody.companies[companyName].projects;
    reqBody.companies[companyName].projects = projects.reduce((acc, item) => {
      acc[item.id] = {
        roles: item.roles ?? [],
        groups: item.groups ?? [],
        userGroups: item.userGroups ?? [],
      };

      return acc;
    }, {});
    reqBody.companies[companyName].projectIds = projects.map((project) =>
      parseInt(project.id)
    );

    delete reqBody.companies[companyName].projectList;
  }

  delete reqBody.companies[companyName].currentProject;

  if (isCreate) {
    delete reqBody.divisionId;
  }

  return reqBody;
};

export const getProjectValidator = () => {
  return async (projectId, allValues, props) => {
    const projects = get(
      allValues,
      // make path to project object in array to show error only for this item
      props.finalSource.toString().split('.').slice(0, -2).join('.'),
      []
    );
    const filteredProjects = projects.filter(
      (project) => project.id?.toString() === projectId?.toString()
    );

    if (filteredProjects.length > 1) {
      return 'Please select a unique Project.';
    }

    return undefined;
  };
};

export const getRoleGroupValidator = () => {
  return async (_value, allValues, props) => {
    const project = get(
      allValues,
      // make path to project object in array to show error only for this item
      props.finalSource.toString().split('.').slice(0, -1).join('.'),
      {}
    );

    if (
      (!Array.isArray(project.roles) || project.roles.length === 0) &&
      (!Array.isArray(project.groups) || project.groups.length === 0)
    ) {
      return 'Please select a Role or Group';
    }

    return undefined;
  };
};

export const getEffectivePermissionWithProjectId = (projects, projectId) => {
  const project = projects.find(
    (project) => project.id.toString() === projectId.toString()
  );

  return get(project, 'effectivePermissions', []);
};

export const generateRandomCode = () => Math.floor(1000 + Math.random() * 9000);
